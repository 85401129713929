exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chamonix-js": () => import("./../../../src/pages/chamonix.js" /* webpackChunkName: "component---src-pages-chamonix-js" */),
  "component---src-pages-cortina-js": () => import("./../../../src/pages/cortina.js" /* webpackChunkName: "component---src-pages-cortina-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-benefits-js": () => import("./../../../src/pages/product/benefits.js" /* webpackChunkName: "component---src-pages-product-benefits-js" */),
  "component---src-pages-product-gallery-js": () => import("./../../../src/pages/product/gallery.js" /* webpackChunkName: "component---src-pages-product-gallery-js" */),
  "component---src-pages-product-index-js": () => import("./../../../src/pages/product/index.js" /* webpackChunkName: "component---src-pages-product-index-js" */),
  "component---src-pages-xplr-js": () => import("./../../../src/pages/xplr.js" /* webpackChunkName: "component---src-pages-xplr-js" */),
  "component---src-templates-basecamp-js": () => import("./../../../src/templates/basecamp.js" /* webpackChunkName: "component---src-templates-basecamp-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-routes-js": () => import("./../../../src/templates/routes.js" /* webpackChunkName: "component---src-templates-routes-js" */)
}

