module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","it","fr"],"defaultLanguage":"en","siteUrl":"https://thenorthface.bokenhub.com/","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","it","fr"],"defaultNS":"index","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/event/:uid","getLanguageFromPath":false,"excludeLanguages":["en","it","fr"]},{"matchPath":"/:lang?/event/:uid","getLanguageFromPath":true,"languages":["en","it","fr"]},{"matchPath":"/routes/:uid","getLanguageFromPath":false,"excludeLanguages":["en","it","fr"]},{"matchPath":"/:lang?/routes/:uid","getLanguageFromPath":true,"languages":["en","it","fr"]}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
